import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
  <div className="flex items-center justify-center p-8">
    <Link to="/">
      <StaticImage
        src="../images/header/storage-assurance-logo-white-bg.png"
        alt="Storage Assurance"
        placeholder="tracedSVG"
        width={400}
      />
    </Link>
  </div>
)

export default Header